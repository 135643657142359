import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { PropertyGroup } from '../../components';

import css from './ListingPage.module.css';

const SectionDetailsMaybe = props => {
  const { publicData, customConfig } = props;
  const { listing, filters } = customConfig || {};

  if (!publicData || !customConfig || !listing?.enumFieldDetails) {
    return null;
  }

  const pickExtendedData = filterConfig => (rows, key) => {
    const publicDataValue = publicData[key];
    if (publicDataValue) {
      const filterIfItExists = filterConfig.find(f => f.id === key);
      const filterOptions = filterIfItExists?.config?.options || [];
      const value =
        filterOptions.find(o => o.key === publicDataValue)?.label ||
        publicDataValue;
      const label =
        filterIfItExists?.label ||
        `${key.charAt(0).toUpperCase()}${key.slice(1)}`;

      return rows.concat({ key, value, label });
    }
    return rows;
  };

  const existingExtendedData = listing?.enumFieldDetails.reduce(
    pickExtendedData(filters),
    []
  );
  return existingExtendedData ? (
    <div className={css.sectionDetails}>
      {/* <h2 className={css.detailsTitle}>
        <FormattedMessage id="ListingPage.detailsTitle" />
      </h2> */}
      <ul className={css.details}>
        <li className={css.detailsRow}>
          <span className={css.detailLabel}>Brand: </span>
          <span>{publicData && publicData.brand}</span>
        </li>
        <li className={css.detailsRow}>
          <span className={css.detailLabel}>Color: </span>
          <span>{publicData && publicData.colour}</span>
        </li>
        <li className={css.detailsRow}>
          <span className={css.detailLabel}>Category: </span>
          <span>{publicData && publicData.category}</span>
        </li>
        <li className={css.detailsRow}>
          <span className={css.detailLabel}>Size: </span>
          <span>{publicData && publicData.size}</span>
        </li>
        <li className={css.detailsRow}>
          <span className={css.detailLabel}>Type: </span>
          <span>{publicData && publicData.type}</span>
        </li>
      </ul>
    </div>
  ) : null;
};

export default SectionDetailsMaybe;
